@import "./variables.scss";

.organization-purchase-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1000;
    &::-webkit-scrollbar {
        display: none;
    }
    * {
        color: #1a3650;
        font-family: "Assistant";
    }
    .x {
        width: 100%;
        position: absolute;
        z-index: 100;
        box-sizing: border-box;
        .arrow {
            transform: rotateY(180deg);
            cursor: pointer;
            width: auto;
            height: 10vh;
            padding: 20px;
        }
    }
    .header {
        font-size: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10vh;
        position: relative;
        height: 40vh;
        .background {
            padding: 10vh 15vw;
            background-color: #c1dee2;
            clip-path: polygon(0 35%, 100% 15%, 100% 100%, 0% 100%);
            width: 100%;
            box-sizing: border-box;
            height: 100%;
        }
        .text {
            position: absolute;
            z-index: 10;
            left: 12vw;
            right: initial;
            bottom: 15%;
            .product-name {
                font-weight: 800;
                font-size: 4rem;
                width: 100%;
                z-index: 10;
            }
            .purchase-options {
                font-size: 2.25rem;
                font-weight: 600;
                width: 100%;
            }
        }
        .plant {
            position: absolute;
            right: 13vw;
            bottom: -5vh;
            width: 100px;
            height: auto;
            z-index: 1;
        }
        .web {
            position: absolute;
            right: calc(10vw + 100px);
            bottom: 20%;
            z-index: 5;
            height: 40vh;
            width: auto;
        }
    }
    .subtitle {
        font-size: 2.3rem;
        font-weight: 700;
        margin: 6vh 0;
        width: 100%;
        text-align: center;
    }
    .options {
        display: flex;
        align-items: stretch;
        padding: 0 5vw;
        margin-bottom: 5vh;
        justify-content: center;
        .error {
            font-size: 1.5rem;
        }
        .option {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin: 0 1vw;
            box-shadow: 0px 2px 6px #00000047;
            background-color: #fafafa;
            padding-bottom: 5vh;
            box-sizing: border-box;
            .option-title {
                color: #69a1ac;
                border-bottom: .5px #69a1ac solid;
                text-align: center;
                font-size: 1.7rem;
                padding: 4vh 3vh;
                font-weight: 800;
            }
            .details {
                box-sizing: border-box;
                padding: 0 3vh;
                display: flex;
                height: 100%;
                flex-direction: column;
                box-sizing: border-box;
                .price {
                    font-weight: 800;
                    font-size: 1.5rem;
                    padding: 3.5vh 3vw;
                }
                .includes {
                    font-size: 1.3rem;
                    flex: 1;
                    padding: 0 3vw;
                }
                .action-btn {
                    @include ellipse-overflow;
                    cursor: pointer;
                    font-weight: 800;
                    align-self: flex-end;
                    background: #da445a;
                    border: 0;
                    border-radius: 50px;
                    color: white;
                    font-size: 1.8rem;
                    width: fit-content;
                    max-width: 80%;
                    text-align: center;
                    padding: 1vh 3vw;
                    margin: auto;
                    margin-top: 8vh;
                    transition: transform 0.15s linear;
                    transform: scale(1);
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
    &.rtl {
        direction: rtl;
        .x {
            .arrow {
                transform: rotateX(0);
            }
        }
        .header {
            .plant {
                position: absolute;
                left: 10vw;
                right: initial;
                bottom: -5vh;
                width: 90px;
                height: auto;
                z-index: 1;
            }
            .web {
                position: absolute;
                right: initial;
                left: calc(15vw + 30px);
                bottom: 10%;
                z-index: 2;
                height: 32vh;
                width: auto;
            }
            .text {
                right: 10vw;
            }
        }
    }
}

@media screen and (orientation: portrait) {
    .organization-purchase-container.rtl,
    .organization-purchase-container.ltr {
        .x {
            top: 10vh;
        }
        .header {
            padding: 1vh 0vw;
            position: relative;
            display: flex;
            flex-direction: column-reverse;
            overflow: visible;
            box-sizing: border-box;
            height: 30vh;
            .background {
                height: 30vh;
            }
            .text {
                right: 0;
                transform: translateX(-50%);
                .product-name {
                    font-size: 1.75rem;
                }
                .purchase-options {
                    font-size: 1.75rem;
                }
            }
            .web {
                display: none;
                width: 50vw;
                height: auto;
                position: absolute;
                left: 40%;
                bottom: 30%;
                transform: translateX(-50%);
            }
            .plant {
                width: 70px;
                height: auto;
                bottom: 0;
                left: -10px;
            }
        }
        .subtitle {
            text-align: initial;
            font-size: 1.4rem;
            font-weight: 600;
            padding: 1vh 8vw;
            box-sizing: border-box;
        }
        .options {
            flex-direction: column;
            padding: 0 6vw;
            .option {
                margin-bottom: 4vh;
                .option-title {
                    text-align: initial;
                    font-size: 1.4rem;
                    padding: 4vh 0 1.5vh;
                    margin: 0 4vh;
                }
                .details {
                    padding: 0vh 4vh;
                    display: flex;
                    flex-direction: column;
                    .price {
                        font-size: 1.4rem;
                        font-weight: 700;
                        padding-top: 1vh;
                    }
                    .includes {
                        font-size: 1.2rem;
                    }
                    .action-btn {
                        font-size: 1.4rem !important;
                        align-self: flex-end;
                        padding: 0.75vh 7vw !important;
                        font-weight: 700 !important;
                        width: fit-content !important;
                        margin: 4vh 0 !important;
                    }
                }
            }
        }
    }
    .organization-purchase-container.ltr {
        .header {
            .plant {
                right: -10px;
                left: initial;
            }
        }
    }
}
