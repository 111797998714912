@import "./variables.scss";

.ourSolutions-container,
.bought-container {
    margin-top: 5vh;
    .title {
        margin-bottom: 6vh;
    }
    .products,
    .projects-cards {
        display: flex;
        padding: 3vh calc(5% - 9vw);
        gap: 2vw;
        width: 88vw;
        margin: auto;
        flex-wrap: wrap;
        justify-content: flex-start;
        * {
            text-decoration: none;
            color: black;
        }
        .thumbnail-container {
            box-shadow: 0px 3px 6px #00000029;
            display: flex;
            flex-direction: column;
            width: 28vw;
            transform: scale(1);
            transition: transform 0.25s ease-in-out;
            height: 52vh;
            &:hover {
                transform: scale(1.02);
                transition: transform 0.25s ease-in-out;
            }
            .product-img {
                height: 50%;
                overflow: hidden;
                position: relative;
                background-color: rgba(233, 231, 231, 0.79);
                img {
                    height: auto;
                    width: 100%;
                }
                .hilma-tag {
                    box-shadow: 1px 2px 8px #00000029;
                    background-color: white;
                    width: fit-content;
                    position: absolute;
                    left: initial;
                    right: 0;
                    border-radius: 30px 0 0 30px;
                    top: 2vh;
                    padding: 8px 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 3;
                    height: 0.7rem;
                    width: 3.4rem;
                    overflow: hidden;
                    .tag {
                        width: 4rem;
                        height: 3.1rem;
                        overflow: hidden;
                        display: flex;

                        img {
                            min-width: 100%;
                            min-height: auto;
                            margin: auto;
                        }
                    }
                }
            }
            .product-info {
                padding: 0 2vw 0;
                height: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &,
                * {
                    color: #1a3650;
                }
                .name {
                    padding-top: 1rem;
                    font-size: 1.6rem;
                    font-weight: 700;
                }
                .description {
                    padding-top: 0.2rem;
                    font-size: 1rem;
                    width: 100%;
                    max-height: 2.5rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &:after {
                        content:'...';
                    }
                }
                .categories {
                    display: flex;
                    margin: 2vh 0 3vh;
                    flex-wrap: nowrap;
                    overflow-x: scroll;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    .category {
                        border-radius: 20px;
                        padding: 4px 14px;
                        font-weight: 500;
                        border: 1px solid #2d898b;
                        color: #2d898b;
                        font-size: 0.7rem;
                        margin: 0 0 0 4px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
    &.rtl,
    &.he {
        &,
        * {
            text-align: right;
            .thumbnail-container {
                .product-img {
                    .hilma-tag {
                        right: initial;
                        left: 0;
                        border-radius: 0 30px 30px 0;
                        .hilma-logo {
                            width: 7vw;
                        }
                    }
                }
                .product-info {
                    .categories {
                        .category {
                            &:first-child {
                                // margin-right: 0;
                                // margin-left: initial;
                            }
                        }
                    }
                }
            }
        }
    }
}

.remote-login,
.more-info,
.complete-purchase {
    position: absolute;
    bottom: 0.3rem;
    right: 0.3rem;
    display: flex;
    border-radius: 50%;
    height: fit-content;
    width: fit-content;
    padding: 0.3rem;
    align-content: center;
    justify-content: center;
    border: 2px solid white;
    background-color: #fdbd38;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        transform: scale(1.15);
    }

    .MuiSvgIcon-root * {
        color: white;
    }
}

.more-info {
    right: 3rem;
    background-color: #d81159;
}

.complete-purchase {
    right: 5.7rem;
    background-color: #2d898b;
}

@media screen and (orientation: portrait) {
    .ourSolutions-container,
    .bought-container {
        margin-top: 10vh;
        .products,
        .projects-cards {
            flex-direction: column;
            .thumbnail-container {
                width: 85vw;
                margin: 2vh auto;
                .product-img {
                    img {
                        width: 100%;
                        height: auto;
                    }
                    .hilma-tag {
                        top: 4vh;
                        .hilma-logo {
                            width: 22vw;
                        }
                    }
                }
                .product-info {
                    padding: 2vh 3vw;
                    .name {
                        font-size: 1.2rem;
                    }
                    .description {
                        font-size: 1rem;
                    }
                    .categories {
                        font-size: 0.9rem;
                        margin: 1vh 0;
                        .category {
                            padding: 2px 10px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (orientation: landscape) and (max-height: 500px) {
    .ourSolutions-container,
    .bought-container {
        margin-top: calc(14vw + 60vh);

        .products,
        .projects-cards {
            .thumbnail-container {
                width: 29vw;
                margin: 2vh 1vw;
                .product-img {
                    .hilma-tag {
                        padding: 6px 13px;
                        .hilma-logo {
                            width: 9vw;
                        }
                    }
                }
                .product-info {
                    .texts {
                        .name {
                            font-size: 1.25rem;
                        }
                        .description {
                            font-size: 0.9rem;
                        }
                    }
                    .categories {
                        .category {
                            padding: 3px 10px;
                        }
                    }
                }
            }
        }
    }
}

.info-popup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 50vw;
    width: fit-content;
    z-index: 10;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        h4,
        p {
            width: 18vw;
            margin: 0.4rem;
        }
    }
}
