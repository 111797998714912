@import "./variables.scss";

.profile-container,
.reset-password-container,
.support-container {
    display: flex;
    flex-direction: column;
    width: 50vw;
    margin: 2.5vh 0;
    padding-left: 35vw;
    padding-right: 15vw;
    float: right;
    position: relative;
    &.rtl {
        direction: rtl;
        .send-btn {
            margin-right: 1vw;
        }
    }
    :not(.save-button):not(.send-button) > * {
        font-family: "Assistant" ;
        font-size: 1.1rem;
        color: #1a3650;
    }
    .save-button:disabled {
        opacity: 0.8;
        cursor: default;
    }
    .MuiInputBase-root {
        width: 35vw;
    }
    & > div {
        &:not(.buttons), &:not(.MuiAccordion-root) {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 2vh;
            & > div:first-child {
                flex: 1;
            }
        }
    }
    .title {
        font-weight: 800;
        font-size: 1.9rem !important;
        margin: 2vh 0;
        margin-bottom: 7vh;
    }
    .support-title {
        margin-bottom: 1vh;
    }
    .contact-us,
    .reset-password-title {
        margin-bottom: 6vh;
    }
    .input-container {
        position: relative;
        margin-bottom: 4vh;
        & > div:not(:first-child) {
            padding: 0.5vh 1.5vw;
            &.phone {
                direction: rtl !important;
                text-align: left;
            }
        }
        &.ltr {
            input {
                direction: ltr;
            }
            &.phone {
                text-align: left;

                & > div:not(:first-child) {
                    direction: rtl !important;
                }
            }
        }
        &.password-container {
            button {
                font-size: 1.1rem !important;
                // width: 10vw;
                min-width: fit-content;
                font-weight: 700;
                text-decoration: underline;
                color: #3c658b !important;
            }
        }
        &.content {
            align-items: flex-start;
            .label-x {
                padding-top: 1vh;
            }
            .error-msg {
                top: 98%;
            }
        }
        .label-x {
            @include ellipse-overflow;
            font-weight: 800;
        }
        .error-msg {
            // background-color: hotpink;
            position: absolute;
            top: 95%;
            left: 35vw;
            transform: translateX(-100%);
            width: fit-content;
            font-size: 0.9rem !important;
            color: red !important;
            padding: 0.5vh 0 !important;
        }
    }
    .save-btn,
    .send-btn {
        background-color: #1a3650;
        position: relative;
        right: calc(
            100% + 35vw - 6vw
        ); //to edge of component, plus padding to reach the end of screen, minus distance of navbar icon from edge of screen so that it aligns
        transform: translateX(100%);
        * {
            font-size: 2rem;
            color: white !important;
            font-weight: 700;
        }

        // &:hover {
        //     border: 1px solid #1a365040;
        //     * {
        //         color: #1a3650 !important;
        //     }
        // }
    }
    .send-btn {
        right: initial;
        transform: initial;
    }
    .is-missing {
        color: rgb(197, 84, 96) !important;
        font-size: 0.95rem !important;
        font-weight: 600;
    }
    &.ltr {
        float: left;
        padding-left: 15vw;
        padding-right: 35vw;
        .input-container {
            .error-msg {
                left: initial;
                right: 35vw;
                transform: translateX(100%);
            }
        }
        .save-btn {
            left: calc(
                100% + 35vw - 6vw - 2.5vw
            ); //to edge of component, plus padding to reach the end of screen, minus distance of navbar icon from edge of screen
            right: initial;
            transform: translateX(-100%);
        }
        .send-btn {
            float: right;
            margin-left: 1vw;
        }
    }
}

@media screen and (orientation: portrait) {
    .profile-container,
    .support-container {
        width: 80vw;
        padding: 0vh 10vw;
        margin-bottom: 10vh;
        display: flex;
        flex-direction: column;
        .title {
            margin: 4vh 0;
            margin-top: 1vh;
            padding: 0 !important;
        }
        .support-title {
            margin-bottom: 1vh;
        }
        .input-container, .radio-buttons-container {
            flex-direction: column;
            .MuiInputBase-root {
                width: 100%;
            }
            .error-msg {
                right: 0;
                left: initial;
                transform: translateX(0);
            }
        }
        .save-btn {
            left: initial !important;
            right: initial !important;
            transform: translateX(0) !important;
            margin: 3vh auto !important;
        }
        .send-btn {
            margin-top: 5vh;
        }
        &.ltr {
            padding: 0;
            width: 80vw;
            padding: 0vh 10vw;

            .input-container {
                .error-msg {
                    right: initial;
                    left: 0;
                    transform: translateX(0);
                }
            }
        }
    }
}
@media screen and (orientation: landscape) and (max-height: 500px) {
    .profile-container {
        width: 75vw;
        padding-left: 12.5vw !important;
        padding-right: 12.5vw !important;
        & > div > div:nth-child(2) {
            width: 50vw;
        }
        .input-container {
            .error-msg {
                left: 50vw;
                right: initial;
                transform: translateX(-100%);
            }
        }
        .save-btn {
            right: 0 !important;
            left: 50% !important;
            transform: translateX(-50%);
            margin: 10vh auto;
        }
        &.ltr {
            .input-container {
                .error-msg {
                    right: 50vw;
                    left: initial;
                    transform: translateX(100%);
                }
            }
        }
    }
}
