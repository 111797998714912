.tutorials-page-container {
    background-color: #fbfbfb;
    height: 86vh;
    border-top: 10px solid #2d898b70;
    border-bottom: 20px solid #2d898b70;
    box-sizing: border-box;
    .title {
        margin-top: 5vh;
    }
    .tutorials-container {
        .tutorial {
            width: 75vw;
            margin: 7vh auto;
            box-shadow: 2px 2px 7px #00000040;
            font-size: 1.6rem;
            background-color: #f6f6f6;
            padding: 3vh 2vw;
            padding-bottom: 5vh;
            box-sizing: border-box;
            color: #1a3650;
            .heading {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                border-bottom: 1px solid #188894;
                margin-bottom: 3vh;
                svg {
                    height: 42px;
                    width: auto;
                    margin: 1vh 1vw;
                    margin-bottom: 2vh;
                }
                .camera {
                    display: flex;
                    align-items: center;
                    position: relative;
                    .triangle {
                        clip-path: polygon(0 37%, 100% 0, 100% 100%, 0 63%);
                        background-color: white;
                        // border: 1px solid #188894;
                        width: 8px;
                        height: 13px;
                        position: absolute;
                        left: calc(1vw + 24px);
                        top: calc(21px + 1vh);
                        transform: translateY(-50%);
                    }
                }
                .tut-product-name {
                    color: #188894;
                    font-weight: 800;
                    padding-bottom: 1vh;
                    font-size: 1.85rem;
                }
            }
            .tut-title {
                font-weight: 700;
            }
            .details-n-signup-btn {
                display: flex;
                justify-content: space-between;
                .when {
                    font-weight: 700;
                    &:not(&:last-child) {
                        margin-bottom: 0;
                    }
                }
                .where {
                    margin: 0;
                }
                .sign-up {
                    cursor: pointer;
                    font-weight: 700;
                    align-self: flex-end;
                    background: #da445a;
                    border: 0;
                    border-radius: 50px;
                    color: white;
                    font-size: 1.75rem;
                    width: fit-content;
                    padding: 1.5vh 4vw;
                    margin-top: 10vh;
                    transition: transform 0.2s linear;
                    transform: scale(1);
                    &:hover {
                        transform: scale(1.05);
                    }
                    a {
                        text-decoration: none;
                        color: white;
                    }
                }
            }
        }
        .no-tuts {
            font-size: 1.3rem;
            text-align: center;
            margin-top: 5vh;
            color: #1a3650;
            font-weight: 600;
        }
    }
    &.rtl {
        direction: rtl;
    }
    .plant {
        position: fixed;
        bottom: 0vh;
        right: 2vw;
        width: 80px;
        z-index: 2;
        height: auto;
    }
}

@media screen and (orientation: portrait) {
    .tutorials-page-container {
        border-top: 10px solid #2d898b70;
        .title {
            margin-top: 8vh;
        }
    }
}

@media screen and (orientation: landscape) and (max-height: 500px) {
    .tutorials-page-container {
        .title {
            font-size: 1.8rem;
        }
    }
}
