.articles-container {
    margin-top: 15vh;
    margin-bottom: 21vh;
    .error {
        margin: 1vh 1vw 0;
        font-size: 1.2rem;
    }
    .slide-container {
        padding: 8vh; //same
        padding-bottom: 2vh;
        margin: auto;
        // margin-bottom: 10vh;
        width: 98vw;
        box-sizing: border-box;
        overflow: auto;
        display: flex;
        position: relative;
        &::-webkit-scrollbar {
            display: none;
        }
        & > * {
            height: 50vh;
            margin: 0 1vw;
            // box-shadow: 0px 3px 6px #00000029;
            .swiper-slide {
                width: auto /* !important */;
                // min-width: 88.5vh;
            }
        }
        .swiper-button-unique {
            position: absolute !important;
            top: 50% !important;
            width: 6vh !important;
            height: 6vh !important;
            z-index: 100;
            width: 10px;
            height: 10px;
            margin: 0 !important;
            box-shadow: none !important;
            cursor: pointer;
            &.swiper-button-disabled {
                display: none;
            }
        }
        .prev {
            left: 4vh !important;
            right: initial !important;
            transform: translate(-50%, -50%) !important;
        }
        .next {
            right: 4vh !important;
            left: initial !important;
            transform: translate(50%, -50%) rotate(180deg) !important;
        }
    }
    &.rtl {
        .slide-container {
            .next {
                left: 4vh !important;
                right: initial !important;
                transform: translate(-50%, -50%) !important;
            }
            .prev {
                right: 4vh !important;
                left: initial !important;
                transform: translate(50%, -50%) rotate(180deg) !important;
            }
        }
    }
}

@media screen and (orientation: portrait) {
    .articles-container {
        margin: 10vh 0;
        .slide-container {
            padding: 8vw 8vw;
            box-sizing: border-box;
            & > * {
                height: 20vh;
                margin: 0 2.5vw;
            }
            .swiper.swiper-initialized {
                height: 50vh;
            }
            .swiper-wrapper {
                .swiper-slide {
                    margin: 0 35px /* !important */; //will be 0 0 after arrows is resolved
                }
            }
        }
    }
}
