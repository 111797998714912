.solutionsForYou-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FBFBFB;
    width: 100vw;
    margin-bottom: 10vh;
    .heading {
        padding: 1vh 7vw;
        background-color: #2d898b70;
        align-items: center;
        height: 95vh;
        width: 100%;
        display: flex;
        .heading-text {
            flex: 1;
            font-family: "Assistant";
            color: white;
            font-weight: bold;
            text-shadow: 1px 1px 4px #909090a9;
            padding-right: 6vw;
            .sub1 {
                z-index: 1;
                font-size: 6rem;
                line-height: 6rem;
                font-weight: 800;
                padding: 0 6vw;
                position: relative;
                animation: slide-up 0.75s ease-in-out;
            }
            .sub2 {
                z-index: 1;
                font-weight: 500;
                font-size: 1.9rem;
                padding-left: 6vw;
                padding-right: 0;
                padding-top: 3vh;
                position: relative;
                animation: slide-up 1.5s ease-in-out;
            }
        }
        .people-img-container {
            position: relative;
            height: fit-content;
            flex: 1;
            animation: slide-up 1s ease-in-out;
            .people {
                * {
                    z-index: 0;
                }
                z-index: 0;
                width: 46vw;
                height: auto;
                position: absolute;
                left: 16%;
                top: 0%;
                transform: translate(0, -50%);
            }
        }
    }
    .bullet-points-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        margin-top: -5%;
        width: 90%;
        height: fit-content;
        z-index: 10;
        .bullet-point {
            width: 20vw;
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            .sub {
                text-align: center;
                font-family: "Assistant";
                font-size: 1.3rem;
                font-weight: 600;
                width: 100%;
                height: fit-content;
            }
            .icon-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 13vw;
                height: 13vw;
                border-radius: 50%;
                background-color: white;
                box-shadow: 3px -3px 4px #00000029;
                margin-bottom: 14px;
                img {
                    width: 8vw;
                    height: auto;
                    margin: auto;
                }
            }
        }
    }
    &.rtl {
        &,
        * {
            text-align: right;
        }
        .heading-text {
            .sub2 {
                padding-right: 6vw;
                padding-left: 0;
            }
        }
    }

    @media screen and (orientation: portrait) {
        position: relative;
        height: fit-content;
        padding: 0;
        .heading {
            height: calc(82vw + 11vh + 9rem);
            align-items: flex-start;
            padding: initial;
            .heading-text {
                position: absolute;
                top: 82vw; //~two thirds of img height
                z-index: 2;
                .sub1 {
                    font-size: 3.2rem;
                    line-height: 3.2rem;
                }
                .sub2 {
                    font-size: 1.25rem;
                    padding-top: 1vh;
                }
            }
            .people-img-container {
                display: flex;
                justify-content: flex-end;
                position: initial;
                top: 0;
                left: 0;
                .people {
                    width: 72vw;
                    position: relative;
                    left: initial;
                    right: initial;
                    top: initial;
                    transform: initial;
                    height: 75vw;
                }
            }
        }
        .bullet-points-container {
            position: relative;
            top: 100%;
            flex-direction: column;
            padding-top: 7vh;
            width: 100%;
            .bullet-point {
                height: fit-content;
                width: 100vw;
                background-color: white;
                border-radius: initial;
                box-shadow: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 5vh;
                margin-top: 2vh;
                .icon-container {
                    background-color: white;
                    border-radius: 50%;
                    box-shadow: 2px -2px 8px #00000048;
                    width: 40vw;
                    height: 40vw;
                    img {
                        width: 27vw;
                        height: 27vw;
                    }
                }
                .sub {
                    position: relative;
                    width: 50vw;
                    font-size: 1.3rem;
                    top: initial;
                    right: initial;
                    left: initial;
                    transform: initial;
                }
            }
        }
    }

    @media screen and (orientation: portrait) and (min-width: 400px) {
        .homepage-container {
            .heading {
                .heading-text {
                    .sub1 {
                        font-size: 3.5rem;
                    }
                }
            }
        }
    }

    @media screen and (orientation: portrait) and (min-width: 700px) {
        .heading {
            height: calc(68vw + 2vh + 4.45rem);
            padding-bottom: 10vw;
            .heading-text {
                top: 68vw; //initial top plus padding-bottom, so that words will be right below image
                .sub1 {
                    font-size: 4.5rem;
                }
                .sub2 {
                    font-size: 2.125rem;
                }
            }
        }
        .bullet-points-container .bullet-point {
            .icon-container {
                width: 28vw;
                height: 28vw;
                img {
                    width: 16vw;
                    height: 16vw;
                }
            }
            .sub {
                font-size: 2rem;
            }
        }
    }
}

@media screen and (orientation: landscape) and (max-height: 500px) {
    .solutionsForYou-container {
        .heading {
            .heading-text {
                .sub1 {
                    font-size: 3.5rem;
                    line-height: 4rem;
                }
                .sub2 {
                    font-size: 1.35rem;
                }
            }
        }
        .bullet-points-container {
            .bullet-point {
                .sub {
                    font-size: 1.1rem;
                }
            }
        }
    }
}
@keyframes slide-up {
    0% {
        opacity: 0;
        top: 30px;
    }
    50% {
        opacity: 0;
        top: 30px;
    }
    100% {
        opacity: 1;
        top: 0;
    }
}
