#material-popup {
    #popup {
        padding: 10vh 6vw;
        padding-top: 6vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        * {
            color: #1a3854;
        }
        .icon {
            height: 10rem;
            margin-bottom: 2rem;

            img {
                height: 100%;
                width: auto;
            }
        }
        .go-to-login,
        .contact-us-popup {
            z-index: 10;
        }
        .buttons {
            z-index: 10;
            display: flex;
            gap: 1rem;
            justify-content: flex-end;
            width: 100%;

            button {
                background-color: white;
                margin-top: 2rem;
                transition: 0.5s;

                &:hover {
                    background-color: #2d898b70;
                }
            }
        }
        .x-button {
            width: 100%;
            cursor: pointer;
        }
        .title {
            font-weight: 700;
            font-size: 1.75rem;
            margin-bottom: 4vh;
        }
        .text {
            font-size: 1.5rem;
            margin-bottom: 3vh;
        }

        .plant-img {
            opacity: 0.5;
            z-index: 0;
            position: absolute;
            bottom: 1rem;
            height: 60%;
        }
    }
}
