@import "./variables.scss";

.receipts-container {
    margin: auto;
    margin-top: 2vh;
    position: relative;

    display: flex;
    flex-direction: column;
    width: 65vw;
    margin: 2.5vh 0;
    padding-left: 18vw;
    padding-right: 15vw;
    padding-bottom: 5vh;
    overflow-x: hidden;

    .name-and-status {
        color: $menuDarkColor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .product-date {
            &.rtl {
                margin-left: 2vw;
            }
            &.ltr {
                margin-right: 2vw;
            }
            .product-name {
                font-weight: 800;
            }
        }
    }

    .title {
        font-weight: 800;
        font-size: 1.9rem !important;
        margin: 2vh 0;
        margin-bottom: 7vh;
        color: $menuDarkColor;
    }

    .refund {
        background-color: rgba(255, 42, 42, 0.08);
        color: rgb(255, 42, 42);
    }
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 !important;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50vw;
    color: $menuDarkColor;
    padding: 8px 41px 16px;

    .field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        width: 50%;
        height: 5vh;

        .label {
            margin: 0.4rem;
        }

        .value {
            margin: 0.4rem;
            width: 20%;
        }
    }
}

@media screen and (orientation: portrait) {
    .receipts-container {
        width: 75%;
        padding: 0;
        margin: auto;
        margin-top: 2vh;

        .name-and-status {
            .product-date {
                &.rtl {
                    margin-left: 4vw;
                }
                &.ltr {
                    margin-right: 4vw;
                }
                .product-name {
                    font-weight: 800;
                }
            }
        }

        .info {
            padding: 8px 18px 16px;
            .field {
                width: 80%;
            }
        }
    }
}
