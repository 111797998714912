.homepage-container,
.product-container,
.bought-container,
.tutorials-page-container {
    overflow-x: hidden;
    z-index: -1;
    // position: relative;
    .title {
        color: #1a3650;
        font-size: 2.4rem;
        font-weight: 800;
        font-family: "Assistant";
        padding-left: 12vw; //to align with first bullet point. (explanation: each BP is 12vw wide. 100vw (full screen) minus each bullet point = 64vw of space. 64/4 to find width between each BP or between BP and edge. = 14.5vw = that's the space from beginning of screen to first BP)
        padding-right: 3vw;
        padding-bottom: 1vh;
        border-bottom: 1px solid #1a3650;
        width: fit-content;
    }
    &.rtl,
    &.he {
        &,
        * {
            direction: rtl;
            .title {
                padding-right: 12vw;
                padding-left: 3vw;
            }
        }
    }
}

.about-container {
    padding: 2vh 25vw;

    &.rtl {
        &,
        * {
            direction: rtl;
        }
    }
    .title {
        text-align: center;
        font-weight: bold;
        font-size: 1.3rem;
        margin-bottom: 2vh;
        color: #1a3650;
    }
    .content {
        z-index: 10;
        margin-bottom: 5vh;
        font-size: 1.19rem;
        line-height: 1.75rem;
        color: #1a3650;
    }

    a {
        text-decoration: none;
    }

    .buttons {
        display: flex;
        width: 100%;
        justify-content: center;

        .button {
            background-color: #1a3650;
            border: 1px solid #1a3650;
            transition: .5s;
            * {
                color: white;
            }
    
            &:hover {
                background-color: white;
                border: 1px solid #1a3650;
            * {
                color: #1a3650;
            }
            }
        }
    }

    .plant {
        height: 30vh;
        width: auto;
        position: fixed;
        opacity: .4;
        z-index: -1;
        bottom: 1vh;
        left: 1vw;
    }
}

@media screen and (orientation: portrait) {
    .homepage-container,
    .tutorials-page-container {
        .title {
            font-size: 1.2rem;
            padding-left: 8vw !important;
            padding-right: 3vw !important;
        }
        .swiper-slide.swiper-slide-active {
            width: fit-content /* !important */;
        }
    }
    .about-container {
        padding: 2vh 7vw;
    }
    &.rtl {
        .title {
            padding-right: 8vw !important;
            padding-left: 3vw !important;
        }
    }
}

// standing ipad
@media screen and (orientation: portrait) and (min-width: 700px) {
    nav.nav {
        height: 8vh;
        img {
            height: 4vh;
        }
    }
    .homepage-container,
    .product-container,
    .tutorials-page-container {
        .title {
            font-size: 3rem;
        }
    }
}

@media screen and (orientation: landscape) and (max-height: 500px) {
    .homepage-container .title,
    .product-container .title,
    .tutorials-page-container .title {
        font-size: 2rem !important;
    }
    .about-container {
        padding: 3vh 20vw;
    }
}
