.purchase-data-container {
    width: 100vw;
    height: 86vh;
    box-sizing: border-box;
    color: #1a3650;
    z-index: 10;
    overflow: hidden;

    &::-webkit-scrollbar {
        display: none;
    }

    .header {
        height: 30vh;
        width: 100%;
        position: relative;
        color: #1a3650;

        .text {
            width: fit-content;
            height: fit-content;
            max-width: 80vw;
            max-height: 20vh;
            padding-right: 10vw;
            padding-top: 3vh;
            z-index: 100;
            position: relative;

            .product-name {
                font-weight: 800;
                font-size: 4rem;
            }
            .subtitle {
                font-size: 2rem;

            }
        }

        .background {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 40vh;
            width: 100vw;
            z-index: -1;
            padding: 10vh 15vw;
            background-color: #c1dee2;
            clip-path: polygon(0 65%, 100% 50%, 100% 100%, 0% 100%);
            box-sizing: border-box;
        }

        .plant {
            position: absolute;
            height: 20vh;
            width: auto;
            bottom: -2vh;
            left: 16vw;
            z-index: 10;
        }
    }

    .messages-container {
        .payment-messages {
            height: fit-content;
            margin-top: 5vh;
            overflow: hidden;
            position: relative;
            box-sizing: border-box;
            padding: 2vh 10vw;
            .content {
                width: fit-content;
                h2,
                h4 {
                    margin: 0;
                    font: normal normal normal 25px Assistant;
                    letter-spacing: 0px;
                    color: #1a3650;
                }
                h2 {
                    font-weight: 800;
                }
                h4 {
                    font-weight: 400;
                }
                .buttons {
                    margin-top: 2vh;
                    .general {
                        color: #1a3650;
                        font: normal normal 500 20px Assistant;
                        letter-spacing: 0;
                        border: 1px solid #1a3650;
                        box-sizing: border-box;
                        margin-left: 2vw;
                        transition: .3s;

                        &:hover {
                            background-color: #1a365027;
                        }
                    }
                    .orange-button {
                        font: normal normal 800 25px Assistant;
                        padding: 1vh 3vw;
                        margin-left: 2vw;
                        transition: 0.3s;
                        background: #ffbd38 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #00000057;
                        border-radius: 48px;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
    }

    .purchase-data {
        height: fit-content;
        overflow: auto;
        display: flex;
        flex-direction: column;
        padding: 2vw 10vw 5vh;

        .info {
            max-width: 80vw;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .title {
                box-sizing: border-box;
                margin: 0.5rem;
                font-weight: 800;
                font-size: 2rem;
                width: 52vw;
                z-index: 10;
                color: #1a3650;
                padding: 1vh 2vw 1vh 0;
                border-bottom: 1px solid #1a3650;
            }

            .user-data, .general-info {
                width: 52vw;

                .data {
                    justify-content: space-between;
                    display: flex;
                    margin: 1vh 0;
                    width: 100%;
                    font-size: 1.2rem;

                    label {
                        width: fit-content;
                        max-width: 20vw;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        font-weight: bold;
                        padding-right: 2vw;
                    }

                    input {
                        width: 23vw;
                        outline: none;
                        border: none;
                        padding: 1vh 1vw;
                        border-radius: 5px;

                        &:disabled {
                            background-color: rgba(219, 219, 219, 0.39);
                        }
                    }
                }
            }

            .restrictions {
                padding-right: 2vw;
            }
        }

        .buttons {
            box-sizing: border-box;
            display: flex;
            gap: 5px;
            justify-content: flex-end;

            .move-to-purchase {
                border: 0.5px solid #1a3650;
                transition: 0.3s;
                color: white;
                background-color: #1a3650;
                padding-right: 3vw;
                padding-left: 3vw;
                font-weight: bold;

                &:disabled {
                    opacity: .7;
                }

                .button-content {
                    height: 2rem;
                    width: 3.5rem;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media screen and (orientation: portrait) {
    flex-direction: column;
    gap: 5vh;

    .background {
        display: none;
    }
}
// }
