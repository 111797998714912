/*
    * small / medium / big - size of the rect
    * opacity- s / m / h - how much opacity the rect will have
    * index- s / m / h - z-index height
    * animation - if we want them to rotate
    * transform-x - transform he obj to the middle
    * *any color* - the colo of the rect
*/

.rect {
    z-index: 10;
    width: 32px;
    height: 32px;
    top: 20vh;
    right: 10vw;
    position: absolute;
    transform: rotate(30deg);

    &.small {
        width: 12px;
        height: 12px;
    }
    &.medium {
        width: 22px;
        height: 22px;
    }
    &.big {
        width: 27px;
        height: 27px;
    }

    &.opacity-s {
        opacity: 0.3;
    }
    &.opacity-m {
        opacity: 0.54;
    }
    &.opacity-h {
        opacity: 0.81;
    }

    &.animation {
        animation: spin 1s ease-in-out !important;
    }
    &.transform-x {
        transform: translateX(-50%);
    }

    &.purple {
        background-color: #2b44ff;
    }
    &.neon-carrot {
        background-color: #ff9b21;
    }
    &.gray {
        background-color: #799db3;
    }
    &.yellow {
        background-color: #ffbd38;
    }
    &.lemon {
        background-color: #fdd991;
    }
    &.blue-gray {
        background-color: #93aabe;
    }
    &.blue {
        background-color: #81bdc3;
    }

    &.index-s {
        z-index: 2;
    }
    &.index-m {
        z-index: 5;
    }
    &.index-h {
        z-index: 10;
    }
}

@keyframes spin {
    0% {
        transform: rotate(120deg) scale(0.75);
        opacity: 0;
    }
    20% {
        opacity: 0.5;
    }
    100% {
        transform: rotate(30deg) scale(1);
        opacity: 1;
    }
}

.circle {
    position: absolute;
    border-radius: 50%;
    right: initial;
    top: initial;

    &.blue {
        background-color: #3d658b;
    }
    &.yellow {
        background-color: #ffbd38;
    }

    &.extra-tiny {
        width: 3vw;
        height: 3vw;
    }
    &.tiny {
        width: 4vw;
        height: 4vw;
    }
    &.small {
        width: 6vw;
        height: 6vw;
    }
    &.medium {
        width: 8.5vw;
        height: 8.5vw;
    }
    &.large {
        width: 10vw;
        height: 10vw;
    }
    &.big {
        width: 25vw;
        height: 25vw;
    }

    &.transform {
        transform: translate(-50%, -50%);
    }

    &.opacity-l {
        opacity: 0.5;
    }
    &.opacity-m {
        opacity: 0.6;
    }
    &.opacity-h {
        opacity: 0.7;
    }

    &.index-l {
        z-index: 20;
    }
    &.index-m {
        z-index: 10;
    }
    &.index-h {
        z-index: 100;
    }

}
