.download-page-container {
    padding: 0 12vw;
    height: calc(86vh - 18px);
    display: flex;
    justify-content: space-between;
    border-bottom: 18px solid #2d898b70;
    position: relative;
    * {
        color: #1a3650;
        font-size: 1.6rem;
        font-weight: 500;
    }
    $font-size: 2.8vh;
    .content {
        width: 50vw;
        .name {
            font-size: $font-size * 2.5;
            font-weight: 800;
            margin-bottom: 2vh;
            color: #40688d;
        }
        .slogen {
            font-size: $font-size * 1.5;
            font-weight: 800;
            margin-bottom: 4vh;
        }
        .todownload-container {
            margin-bottom: 3.5vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .text {
                margin-bottom: 2.5vh;
                font-size: $font-size * 1.3;
            }
            .buttons {
                display: flex;
                flex-wrap: wrap;
                gap: 1vh;
                & > * {
                    background-color: transparent;
                    width: fit-content;
                    border: 0;
                    padding: 0;
                    cursor: pointer;

                    & > * {
                        width: auto;
                    }
                }
                .button-app {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .app {
                        height: $font-size * 2.5;
                    }
                    :disabled {
                        opacity: 0.4;
                        pointer-events: none;
                    }
                    .create-qr-code {
                        margin-bottom: 12vh;
                        margin-top: 2.5vh;
                        width: fit-content;
                        font-size: 1.8vh;
                        font-weight: 500;
                        // border-bottom: $darkBlue;
                        border-bottom: 1px solid;
                    }
                }
            }
        }
        .app-link-text {
            font-weight: 800;
            margin-bottom: 2.5vh;
            margin-top: 10vh;
            font-size: $font-size * 1.3;
        }
        .to-website {
            border-radius: 50px;
            width: fit-content;
            min-width: 15vw;
            text-align: center;
            font-weight: 800;
            background-color: #fdbd38;
            box-shadow: -2px 2px 7px rgba(0, 0, 0, 0.349);
            border: 0;
            padding: 1.3vh 5vw;
            cursor: pointer;
            font-size: $font-size * 1.3;
            transition: transform 0.15s linear;
            transform: scale(1);
            &:hover {
                transform: scale(1.05);
            }
            &:disabled {
                background-color: #fdbf3886;
                color: #00000086;
            }
        }
    }
    .side-img {
        position: relative;
        .web {
            position: absolute;
            left: -4.5vw;
            top: 8vh;
            height: 45vh;
            width: 43.5vh;
            z-index: 2;
        }
        .top-leaf {
            position: absolute;
            left: calc(43.5vh - 4.5vw);
            top: 2vh;
            width: 15vw;
            height: auto;
            transform: translateX(-50%);
        }
        .bottom-leaf {
            position: absolute;
            left: calc(43.5vh - 4.5vw);
            top: 9vh;
            width: 7.5vw;
            height: auto;
            transform: translateX(-50%);
        }
        .web-girl {
            position: absolute;
            left: -8.5vw;
            height: 49vh;
            width: auto;
            top: 37vh;
            z-index: 3;
        }
    }
    .plant {
        position: absolute;
        bottom: -18px;
        right: calc(12vw / 2 - 1vw);
        transform: translateX(50%);
        width: 7vw;
        height: auto;
    }
    &.rtl {
        * {
            direction: rtl;
        }
    }
}

@media screen and (orientation: portrait) {
    .download-page-container {
        padding: 0;
        height: calc(90vh - 18px);
        .content {
            width: 90vw;
        }
    }
}
