.nav {
    background-color: #f4f4f4;
    height: 14vh;
    width: 100%;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.199);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3vw;
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    position: sticky;
    top: 0;
    z-index: 1000;
    &::-webkit-scrollbar {
        display: none;
    }
    .nav-option {
        color: #1a3650;
        font-size: 1.2rem;
    }

    .profile-lang-container {
        flex: 1;
        display: flex;
        align-items: center;
        .profile-icon-container {
            width: fit-content;
            height: fit-content;
            border: 0;
            background-color: transparent;
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;

            .login-button {
                border: 1px solid white;
            }

            * {
                width: 35px;
                height: 35px;
            }
        }

        .lang {
            margin: 0 2.5vw;
            .lang-portrait {
                fill: white;
            }
            a {
                color: #1a3650;
                height: 35px;
                text-decoration: none;
                padding: 5% 5px;
                margin: 0 2vw;
                border-radius: 3px;
                &:visited {
                    color: #1a3650;
                }
                
                * {
                    font-size: 0.77rem;
                }
                .language-button {
                    padding: .3vh 1.2vw;
                    font-weight: 600;
                    font-size: 1rem;
                    border: 1.5px solid #1A3854;
                    border-radius: 34px;
                    &:hover {
                        background-color: rgba(173, 173, 173, 0.179);
                    }
                }
                img {
                    height: 20px;
                    width: 20px;
                    padding: 0;
                }
            }
            .css-1s2u09g-control {
                border-radius: 30px;
                border: 2px solid #1a3650;
                height: 35px;
            }
            .css-319lph-ValueContainer {
                padding: 0px 8px;
                height: 100%;
            }
        }
    }

    .links {
        flex: 5;
        display: flex;
        gap: 4vw;
        align-items: center;
        // justify-content: space-between;
        flex-direction: row;
        padding: 0 1vw;
        & > * {
            text-decoration: none;
            color: #1a3650;
            font-size: 1.2rem;
            font-weight: 600;
            text-align: center;
            transition: font-weight 0.25s ease-in-out;
        }
        .nav-option.true {
            transition: font-weight 0.25s ease-in-out;
            font-weight: 800;
        }
        .link-break {
            margin: 0;
            opacity: 0.6;
        }
    }
    img {
        padding-left: 2.5vw;
        height: 6.5vh;
    }
    &.rtl {
        .links {
            flex-direction: row-reverse;
        }
    }
}
.dd-menu-item {
    &,
    * {
        font-family: "Assistant" !important;
    }
}

@media screen and (orientation: portrait) {
    .nav {
        height: 10vh;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.256);
        .profile-lang-container {
            display: none;
        }
        & > img {
            height: 30px;
            width: auto;
        }
        .hamburger {
            cursor: pointer;
            .line {
                width: 28px;
                height: 3px;
                margin: 5px 0;
                background-color: #1a3854;
            }
        }
        .links {
            display: none;
        }
        &.true {
            //portrait navbar design
            background-color: #1a3650;
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 100;
            flex-direction: column;

            .nav-option,
            .lang,
            .language,
            .login-button {
                color: white;
                direction: ltr;
                text-align: left;
            }
            & > img {
                display: none;
            }
            .profile-lang-container {
                display: flex;
                width: 100%;
                flex-direction: row-reverse;
                justify-content: space-between;
                margin-top: 1rem;
                padding: 0vh 0vw;
                box-sizing: border-box;
                height: 10vh;
                .profile-icon-container {
                    padding: 0;
                    * {
                        height: 35px;
                        width: 35px;
                    }
                }
                .lang {
                    position: absolute;
                    // top: 0;
                    left: 3rem;
                    margin: 0 10vw;
                    width: fit-content;
                    .css-1s2u09g-control {
                        border-radius: 30px;
                        border: 2px solid white;
                        background-color: #1a3650;
                        color: white !important;
                    }
                }
            }
            .hamburger {
                display: none;
            }
            .links {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex: 10;
                & > * {
                    width: 80vw;
                    margin: 4vh auto;
                    flex: initial;
                    &:first-child {
                        margin-top: 8vh;
                    }
                }
                .link-break {
                    margin: 0;
                }
            }
            &.rtl {
                * {
                    direction: rtl;
                    text-align: right;
                }
                .profile-lang-container {
                    .lang {
                        right: 0;
                    }
                }
            }
        }
    }
}
@media screen and (orientation: landscape) and (max-height: 500px) {
    .nav {
        * {
            font-size: 1rem;
        }
    }
}
