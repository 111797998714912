@import "./variables.scss";

.product-container {
    border-top: 13px solid #2d898b70;
    background-color: #fbfbfb;
    * {
        font-family: "Assistant";
    }
    .loading {
        margin-right: calc(50vw - 20px);
        margin-left: calc(50vw - 20px);
        margin-top: 10vh;
    }
    .gallery {
        padding: 0 1vw;
        margin-bottom: 20vh;
        position: relative;
        .swiper {
            margin-top: 3vh;
            .swiper-slide.swiper-slide-active {
                height: fit-content;
                overflow-x: auto;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            img {
                height: 35vh;
                width: auto;
                margin: 1vh 0;
            }
        }
        .swiper-button-unique {
            position: absolute !important;
            top: 60% !important;
            width: 6vh !important;
            height: 6vh !important;
            z-index: 100;
            width: 10px;
            height: 10px;
            margin: 0 !important;
            box-shadow: none !important;
            cursor: pointer;
            &.swiper-button-disabled {
                display: none;
            }
        }
        &:hover {
            .prev, .next {
                display: initial;
            }
            .swiper-button-disabled {
                display: none;
            }
        }
        .next {
            display: none;
            left: 6vh !important;
            right: initial !important;
            transform: translate(-50%, -50%) !important;
        }
        .prev {
            display: none;
            right: 6vh !important;
            left: initial !important;
            transform: translate(50%, -50%) rotate(180deg) !important;
        }
    }
    .product-features-container {
        margin-bottom: 20vh;
        .feature {
            width: 75vw;
            box-sizing: border-box;
            margin: 3.5vh auto;
            box-shadow: 0px 3px 6px #00000029;
            padding: 3vh 6vw;
            padding-left: 6vw;
            padding-right: 17vw;
            display: flex;
            align-items: center;
            background-color: white;
            .feature-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                img,
                svg {
                    height: 8vh;
                    width: auto;
                }
            }
            .text {
                flex: 5;
                color: #1a3650;
                font-size: 1.4rem;
                padding: 0 2vw;
                .name {
                    font-weight: 800;
                }
                .description {
                    font-size: 1.3rem;
                }
            }
        }
    }
    .video-container {
        position: relative;
        margin-bottom: 20vh;
        height: 35vw;
        .blue-bg {
            position: absolute;
            background-color: rgba(193, 222, 226, 0.64);
            clip-path: polygon(0 45.5%, 100% 24.5%, 100% 95%, 0% 95%);
            overflow: hidden;
            width: 100vw;
            height: 35vw;
            box-sizing: border-box;
        }
        .video {
            width: 53vw; //Shalva I've changed that to work with youtube
            height: 30vw;
            position: absolute;
            left: 50%;
            bottom: 15%;
            transform: translateX(-50%);
            z-index: 9;
            box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.3);
        }
    }
    .recommenders-container {
        height: 55vh;
        min-height: fit-content;
        margin-bottom: 20vh;
        position: relative;
        .title {
            margin-bottom: 5vh;
        }
        &.empty {
            display: none;
        }
        .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden {
            height: 100%;
            position: relative;
            .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
                bottom: 0% !important;
                z-index: 2;
            }
            .swiper-wrapper {
                height: 90%;
            }
        }
        .rec-container {
            height: 100%;
            min-height: fit-content;
            width: 52vw;
            margin-left: 1.35vw;
            background-color: #f8f8f8;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            & > * {
                flex: 1;
            }
            .profile {
                height: 21vh;
                max-height: 21vh;
                display: flex;
                & > * {
                    height: 17vh;
                    width: 17vh;
                    border-radius: 50%;
                    align-self: flex-end;
                    box-shadow: 2px 2px 9px #00000059;
                }
                z-index: 10;
            }
            .text {
                height: 35vh;
                max-height: 35vh;
                padding: 5vh 2vw 1vh;
                max-width: 80%;
                text-align: center;
                font-weight: 300;
                display: flex;
                flex-direction: column;
                z-index: 10;
                box-sizing: border-box;
                gap: 1vh;
                .content {
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                    flex: 3;
                    max-height: 5.6rem;
                    &:first-of-type {
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
                .from {
                    font-weight: 600;
                    font-size: 1.2rem;
                    max-height: 20%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
    .tutorials-container {
        margin-bottom: 20vh;
        .tutorial {
            width: 75vw;
            margin: 7vh auto;
            box-shadow: 2px 2px 7px #00000040;
            font-size: 1.6rem;
            background-color: #f6f6f6;
            padding: 3vh 2vw;
            padding-bottom: 5vh;
            box-sizing: border-box;
            color: #1a3650;
            .heading {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                border-bottom: 1px solid #188894;
                margin-bottom: 3vh;
                svg {
                    height: 40px;
                    width: auto;
                    margin: 1vh 1vw;
                    // margin-bottom: 3vh;
                }
                .camera {
                    display: flex;
                    align-items: center;
                    position: relative;
                    .triangle {
                        clip-path: polygon(0 37%, 100% 0, 100% 100%, 0 63%);
                        background-color: white;
                        // border: 1px solid #188894;
                        width: 7px;
                        height: 12px;
                        position: absolute;
                        left: calc(1vw + 23px);
                        top: calc(20px + 1vh);
                        transform: translateY(-50%);
                    }
                }
                .tut-product-name {
                    color: #188894;
                    font-weight: 800;
                    padding-bottom: 1vh;
                    font-size: 1.75rem;
                }
            }
            .tut-title {
                font-weight: 700;
            }
            .details-n-signup-btn {
                display: flex;
                // align-items: center;
                justify-content: space-between;
                .when {
                    font-weight: 700;
                    &:not(&:last-child) {
                        margin-bottom: 0;
                    }
                }
                .where {
                    margin: 0;
                }
                .sign-up {
                    cursor: pointer;
                    font-weight: 700;
                    align-self: flex-end;
                    background: #da445a;
                    border: 0;
                    border-radius: 50px;
                    color: white;
                    font-size: 1.6rem;
                    width: fit-content;
                    padding: 1.5vh 4vw;
                    margin-top: 10vh;
                    transition: transform 0.2s linear;
                    transform: scale(1);
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
    .org-purchase-container {
        padding: 0 5vw;
        display: flex;
        align-items: center;
        height: fit-content;
        max-height: 60vh;
        margin-bottom: 20vh;
        gap: 2vw;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        box-sizing: border-box;
        .text-container {
            box-sizing: border-box;
            max-height: 35vh;
            height: fit-content;
            margin-bottom: 10vh;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            justify-content: space-between;
            .texts {
                display: flex;
                flex-direction: column;
                .text {
                    flex: 1;
                    color: #1a3650;
                    .editor-title {
                        font-size: 1.6rem;
                        font-weight: 800;
                        margin: 1vh 0;
                    }
                    .editor-paragraph {
                        font-size: 1.3rem;
                        line-height: 1.85rem;
                        margin: 0;
                    }
                }
            }
            .slogen {
                font-size: 1.7rem;
                font-weight: 800;
            }
            .link-to-purchase {
                position: absolute;
                bottom: 0;

                .purchase-button {
                    border-radius: 50px;
                    max-width: 45vw;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    border: 2px solid #da445a;
                    color: #da445a;
                    font-weight: 800;
                    font-size: 2vw;
                    padding: 1vh 3vw;
                    background-color: #fbfbfb;
                    cursor: pointer;
                    transition: background-color 0.15s linear;
                    &:hover {
                        background: #da445a;
                        color: white;
                    }
                }
            }
        }
        .img-container {
            // box-shadow: 2px 5px 15px 2px #0000002d;
            display: flex;
            justify-content: center;
            max-width: 40vw;
            min-width: 30vw;
            max-height: 30vw;
            overflow: hidden;
            position: relative;
            border-radius: 10px;
            &.empty {
                overflow-x: visible;
                svg {
                    width: 50vw;
                    height: auto;
                }
            }
            img {
                flex: 1;
                border-radius: 10px;
                min-width: 30vw;
                height: fit-content;
                max-width: 40vw;
            }
        }
    }
    .download-btn-container {
        position: fixed;
        bottom: 0;
        width: 100vw;
        height: 8vh;
        background-color: #f4f4f4;
        box-shadow: 0px -3px 2px #0000000a;
        z-index: 10;
        .download-btn {
            background-color: #fdbd38;
            border: 0;
            cursor: pointer;
            align-items: center;
            border-radius: 50px;
            color: #1a3650;
            font-weight: 800;
            font-size: 1.85rem;
            padding: 1.5vh 0;
            width: fit-content;
            box-shadow: 0px 3px 6px #00000057;
            font-family: "Assistant";
            display: flex;
            flex-direction: row;
            position: absolute;
            top: 0;
            right: 10%;
            left: initial;
            transform: translateY(-50%);
            transition: box-shadow 0.2s ease-in-out;
            animation: none;
            cursor: pointer;
            &:hover{
                animation: box-shadow 0.35s ease-in-out;
                box-shadow: 0px 0px 3px 17px #ffbd6c59;
            }
            &:disabled {
                cursor: auto;
                background-color: #e6cf9f;
                * {
                    color: #596168;
                    stroke: #596168;
                }
                box-shadow: none;
                animation: none;
            }
            .text {
                padding-left: 0;
                padding-right: 3vw;
                position: relative;
                right: 0;
                transition: right 0.3s ease-in-out;
            }
            .download-icon {
                width: 35px;
                padding: 0 2.5vw;
            }
        }
    }
    .bottom {
        position: relative;
        margin-top: 10vh;
        .center-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            width: fit-content;
        }
        .by-hilma {
            font-weight: bold;
            font-size: 1.85rem;
            color: #1a3650;
        }
        .hilma-logo {
            width: 200px;
            margin: 5vh auto;
            margin-bottom: 10vh;
        }
    }
    .plant {
        height: 38vh;
        margin: 2vh 1.8vw;
    }
    &.rtl {
        direction: rtl;
        .download-btn {
            left: 10%;
            right: initial;
            .text {
                padding-right: 0;
                padding-left: 3vw;
                position: relative;
                right: 0;
                transition: right 0.3s ease-in-out;
            }
        }
        .product-features-container {
            .feature {
                padding-right: 6vw !important;
                padding-left: 17vw !important;
            }
        }
        .org-purchase-container {
            .slogen {
                line-height: 2.6rem;
                width: 80%;
            }
            .editor-paragraph {
                margin: 0.5vh 0;
                font-size: 1.5rem;
            }
        }
    }
}

@media screen and (orientation: portrait) {
    .product-container {
        border-top: 10px solid #2d898b70;
        .main-info {
            h1.name {
                font-size: 2.75rem;
                padding: 1vh 6vw;
            }
        }
        .gallery {
            .swiper {
                .swiper-slide {
                    margin: 0 3vw !important;
                    width: fit-content !important;
                    max-width: 60vw !important;
                    img {
                        margin: 1vh 1vw;
                        height: 20vh;
                        max-width: 60vw !important;
                        width: auto;
                    }
                    &:first-child {
                        margin-right: 1vw !important;
                    }
                    &:last-child {
                        margin-left: 1vw !important;
                    }
                }
            }
        }
        .title {
            font-size: 1.35rem;
        }
        .product-features-container {
            margin-bottom: 5vh;
            .feature {
                width: 100vw !important;
                padding: 2vh 9vw !important;
                .feature-icon {
                    height: 4vh;
                    img,
                    svg {
                        height: 6vh;
                        width: auto;
                    }
                }
                .text {
                    flex: 3; //this was 1, idk why
                    margin: auto;
                    padding: 0 5vw !important;
                    .name {
                        font-size: 1.1rem;
                        line-height: 1.2rem;
                    }
                    .description {
                        font-size: 1rem;
                        line-height: 1.3rem;
                    }
                }
            }
        }
        .video-container {
            height: 55vw;
            margin-bottom: 6vh;
            .blue-bg {
                height: 55vw;
            }
            .video {
                height: 43vw;
                width: 80vw;
                bottom: 15%;
            }
        }
        .org-purchase-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 7vh;
            padding: 2vh 8vw;
            max-height: 90vh;
            .text-container {
                position: relative;
                min-width: 85vw;
                max-height: 50vh;
                overflow: hidden;
                padding-bottom: 10vh;
                .texts {
                    width: 85vw;
                    max-width: 85vw;
                    max-height: 40vh;
                    overflow: hidden;
                }
                .text * {
                    font-size: 1rem;
                    overflow: hidden;
                }
                .link-to-purchase {
                    .purchase-button {
                        max-width: 100vw;
                        margin-top: 3vh;
                        font-size: 1.125rem;
                        padding: 3px 16px;
                        border-width: 2px;
                        font-weight: 700;
                        width: fit-content;
                    }
                }
            }
            .img-container {
                margin-top: 3vh;
                max-width: 100%;
                min-width: 100%;
                max-height: 50vw;
                align-self: center;
                img {
                    min-width: 100%;
                    max-width: 100%;
                }
            }
        }
        .tutorials-container {
            *:not(.title) {
                font-size: 1rem;
            }
            .tutorial {
                width: 84vw;
                padding: 3vh 4vw;
                margin: 4vh auto;
                .heading {
                    align-items: center; //in xd the bottom border isnt touching the title, so this doesnt have to be flex end
                    .tut-product-name {
                        font-size: 1.2rem;
                    }
                    .camera {
                        svg {
                            height: 30px;
                        }
                        .triangle {
                            top: calc(15px + 1vh);
                            left: calc(1vw + 18px);
                            width: 5px;
                            height: 9px;
                        }
                    }
                }
                .details-n-signup-btn {
                    flex-direction: column;
                    .details {
                        margin-top: 2vh;
                    }
                    .sign-up {
                        font-size: 1rem;
                        padding: 1vh 25px;
                        margin-top: 3vh;
                    }
                }
            }
        }
        .recommenders-container {
            margin-bottom: 8vh;
            .rec-container {
                position: relative;
                background-color: inherit;
                height: 85%;
                .background {
                    width: 160%;
                    height: 100%;
                }
                .text {
                    max-width: 90%;
                }
            }
        }
        .download-btn-container {
            height: 5vh;
            .download-btn {
                padding: 7px 10px;
                transform: translateY(-60%);
                .text {
                    font-size: 1.3rem;
                }
                .download-icon {
                    width: 20px;
                }
            }
        }
        .bottom {
            margin-top: 15vh;
            .center-text {
                width: 60vw;
                .by-hilma {
                    font-weight: bold;
                    font-size: 1rem;
                    text-align: center;
                    color: #1a3650;
                }
                .hilma-logo {
                    width: 90px;
                    margin: 3vh auto;
                    margin-bottom: 17vh;
                }
            }
        }
        .plant {
            height: 15vh;
            width: auto;
            margin: 2vh 4vw;
        }
        &.rtl {
            .product-features-container {
                .feature {
                    padding: 3vh 6vw !important;
                }
            }
        }
    }
}
@media screen and (orientation: landscape) and (max-height: 500px) {
    .product-container {
        .recommenders-container {
            .rec-container {
                .profile > * {
                    height: 100px;
                    width: 100px;
                }
                .text {
                    .content {
                        font-size: 1rem;
                    }
                    .from {
                        font-size: 1.2rem;
                    }
                }
            }
        }
        .org-purchase-container .text-container {
            .purchase-button {
                max-width: 100vw;
                font-size: 1.1rem;
                font-weight: 700;
                border-width: 2px;
                margin-top: 2vh;
            }
        }
        .bottom {
            margin-top: 20vh;
            .center-text {
                width: fit-content;
                .by-hilma {
                    font-size: 1.3rem;
                }
                .hilma-logo {
                    width: 120px;
                    margin: 12vh auto;
                    margin-bottom: 25vh;
                }
            }
        }
        .download-btn-container {
            .download-btn {
                .download-icon {
                    width: 25px;
                }
                .text {
                    font-size: 1.3rem;
                    font-weight: 700;
                }
            }
        }
    }
}

@keyframes box-shadow {
    0% {
        box-shadow: 0px 3px 6px #00000057;
    }

    1% {
        box-shadow: 0px 3px 6px #ffbd6c59;
    }
    50% {
        box-shadow: 0px 0px 3px 14px #ffbd6c59;
    }
    100% {
        box-shadow: 0px 0px 3px 17px #ffbd6c59;
    }
}
