.iframe {
    width: 100vw;
    height: 85vh;
    border: 0;
    box-shadow: 5px 5px 56px 0px rgba(0, 0, 0, 0.25);

}

.noContent {
    width: 100vw;
    text-align: center;
    font-size: larger;
}