.swiper {
    width: 100%;
    height: 100%;

    .swiper-slide {
        // text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 20px;
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin: 0 1.5vw;
            background-color: #cccccc;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background-color: #3e3e3e;
            }
        }
    }
}
