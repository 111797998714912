.partners-container {
    background-color: #c1dee29c;
    padding: 8vh 0;
    border-bottom: 30px solid #2d898b70;
    position: relative;

    .plant {
        position: absolute;
        bottom: -23px; //slightly less than border-bottom thickness
        right: 2vw;
        width: 90px;
        height: auto;
    }

    .logos {
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;

        .logo {
            height: 22vh;
            margin: 8vh 5vw;
        }

        .hilma {
            width: 25%;
        }
    }
}

@media screen and (orientation: portrait) {
    .partners-container {
        .plant {
            width: 17vw;
        }

        .logos {
            flex-direction: column;
            .logo {
                // height: 16vh;
                width: 50%;
                margin:4vh auto;
            }
        }
    }
}