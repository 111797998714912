.reviews-container {
    margin-top: 15vh;
    margin-bottom: 20vh;
    overflow: visible;
    position: relative;
    * {
        font-family: "Assistant";
        font-size: 1.2rem;
    }
    .title {
        z-index: 3;
        margin-bottom: 3vh;
    }
    .error {
        margin: 1vh 1vw 0;
    }
    .swiper-button-unique {
        position: absolute !important;
        top: 50% !important;
        width: 6vh !important;
        height: 6vh !important;
        z-index: 100;
        width: 10px;
        height: 10px;
        margin: 0 !important;
        box-shadow: none !important;
        cursor: pointer;
        &.swiper-button-disabled {
            display: none;
        }
    }
    &:hover {
        .prev, .next {
            display: initial;
        }
        .swiper-button-disabled {
            display: none;
        }
    }
    .next {
        display: none;
        left: 6vh !important;
        right: initial !important;
        transform: translate(-50%, -50%) !important;
    }
    .prev {
        display: none;
        right: 6vh !important;
        left: initial !important;
        transform: translate(50%, -50%) rotate(180deg) !important;
    }
    .reviews {
        display: flex;
        height: 75vh;
        overflow-y: hidden;
        overflow-x: auto;
        position: relative;
        &::-webkit-scrollbar {
            display: none;
        }
        .review-container {
            width: 100vw;
            height: 70vh;
            position: relative;
            .bg-container {
                width: 100vw;
                position: absolute;
                z-index: 2;
                height: 70vh;
                overflow: visible;
                .girl-wheel {
                    width: 15vw;
                    height: auto;
                    position: absolute;
                    left: 23vw;
                    z-index: 2;
                    bottom: -2%;
                }
                .tall-plant {
                    height: 105%;
                    position: absolute;
                    width: auto;
                    left: 10vw;
                    bottom: -2vh;
                }
                .leafy-plant {
                    position: absolute;
                    bottom: 0%;
                    right: 2vw;
                    height: 32vh;
                }
                .table-lamp {
                    position: absolute;
                    left: 2vw;
                    bottom: 0%;
                    height: 43vh;
                }
                .web {
                    position: absolute;
                    left: 5vw;
                    top: 0%;
                    z-index: 2;
                    width: 22vw;
                    height: auto;
                }
                .web-girl {
                    position: absolute;
                    bottom: -3%;
                    left: 10vw;
                    height: 40vh;
                    width: auto;
                    z-index: 2;
                }
                .bottom-leaf,
                .top-leaf {
                    position: absolute;
                    left: 19vw;
                    z-index: 1;
                    top: 0%;
                    width: 17vw;
                    height: auto;
                }
                .bottom-leaf {
                    width: 8.5vw;
                    height: auto;
                    left: 24vw;
                    top: 14%;
                }
                .tall-lamp {
                    position: absolute;
                    left: 5vw;
                    bottom: 5%;
                    height: 100%;
                    width: fit-content;
                }
                .plant {
                    position: absolute;
                    bottom: 10%;
                    left: 8vw;
                    height: 40vh;
                }
            }
            .profile {
                position: absolute;
                z-index: 2;
                top: 5%;
                right: 5%;
                width: 15vw;
                height: 15vw;
                border-radius: 50%;
                background-color: white;
                box-shadow: 2px 2px 8px #00000068;
                img,
                svg {
                    width: 15vw;
                    height: 15vw;
                    border-radius: 50%;
                }
                &.true {
                    top: 5%;
                    right: 10%;
                }
            }
            .text {
                background-color: rgba(193, 222, 226, 0.64);
                clip-path: polygon(0 28%, 100% 13%, 100% 100%, 0% 100%);
                overflow-y: visible;
                overflow-x: visible;
                width: 100%;
                height: 70vh;
                box-sizing: border-box;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                &.true {
                    clip-path: polygon(0 13%, 100% 28%, 100% 100%, 0% 100%);
                }
            }
            .text-part {
                position: absolute;
                right: 28vw;
                top: 40%;
                z-index: 10;
                * {
                    color: #1a3650;
                }
                &.true {
                    clip-path: polygon(0% 27%, 100% 42%, 100% 95%, 0% 95%);
                }
                .review {
                    font-size: 1.1rem;
                    font-weight: 300;
                    margin-bottom: 2vh;
                    white-space: pre-line;
                    line-height: 1.3rem;
                    max-width: 40vw;
                    max-height: 6.6rem;
                    overflow: hidden;

                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;

                }
                .from,
                .project {
                    font-weight: 600;
                    margin-bottom: 1vh;
                    font-size: 1.3rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 40vw;
                }
            }
        }
    }
    &.ltr {
        .reviews {
            .review-container {
                .profile {
                    left: 5%;
                    right: initial;
                }
                .text {
                    clip-path: polygon(100% 55%, 0 35%, 0% 100%, 100% 100%);
                }
                .text-part {
                    left: 23vw;
                    right: initial;
                }
                .bg-container {
                    .girl-wheel {
                        right: 20vw;
                        left: initial;
                    }
                    .tall-plant {
                        right: 11vw;
                        left: initial;
                    }
                    .leafy-plant {
                        left: 2vw;
                        right: initial;
                    }
                    .table-lamp {
                        right: 3vw;
                        left: initial;
                    }
                    .web {
                        right: 4vw;
                        left: initial;
                    }
                    .web-girl {
                        right: 8vw;
                        left: initial;
                    }
                    .bottom-leaf,
                    .top-leaf {
                        right: 22vw;
                        left: initial;
                    }
                    .bottom-leaf {
                        right: 28vw;
                        left: initial;
                    }
                    .tall-lamp {
                        right: 3vw;
                        left: initial;
                    }
                    .plant {
                        right: 10vw;
                        left: initial;
                    }
                }
            }
        }
    }
}

@media screen and (orientation: portrait) {
    .reviews-container {
        margin-top: 5vh;
        .reviews {
            .review-container {
                .bg-container {
                    height: 50vh;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100vw;
                    overflow: hidden;
                    .bg-1 {
                        height: 50vh;
                        width: 110vw;
                    }
                    .leafy-plant {
                        bottom: 5%;
                        right: 1vw;
                        width: 17vw;
                        height: auto;
                    }
                    .table-lamp {
                        left: 3vw;
                        bottom: 5%;
                        width: 18vw;
                        height: auto;
                    }
                    .web {
                        bottom: 10vh;
                        width: 25vw;
                        left: 2vw;
                        top: initial;
                    }
                    .web-girl {
                        height: 15vh;
                        left: 3vw;
                    }
                    .top-leaf {
                        bottom: 20vh;
                        top: initial;
                        left: 23vw;
                        width: 10vw;
                    }
                    .bottom-leaf {
                        bottom: 20vh;
                        top: initial;
                        left: 24.5vw;
                        width: 7vw;
                    }
                    .tall-lamp {
                        height: 30vh;
                        left: 5vw;
                        width: auto;
                    }
                    .plant {
                        width: 10vw;
                        left: 12vw;
                        height: auto;
                    }
                    .tall-plant {
                        height: auto;
                        position: absolute;
                        left: 0;
                        bottom: 3%;
                        width: 35vw;
                        transform: translateX(-35%);
                    }
                    .girl-wheel {
                        width: auto;
                        position: absolute;
                        left: 8.5vw;
                        z-index: 2;
                        bottom: 2.5%;
                        height: 13vh;
                    }
                }
                .text {
                    padding: 1vh 22vw;
                    padding-top: 24%;
                    height: 50vh;
                    &.true {
                        padding: 1vh 21vw;
                        padding-top: 30%;
                    }
                    &.false {
                        padding-left: 27vw;
                        padding-right: 18vw;
                    }
                    * {
                        font-size: 0.85rem !important;
                        z-index: 3;
                    }
                    .from,
                    .project {
                        margin-bottom: 0;
                    }
                }
                .profile {
                    width: 16vw;
                    height: 16vw;
                    &.true {
                        top: 30%;
                        right: 5%;
                    }
                    img,
                    svg {
                        width: 16vw;
                        height: 16vw;
                        border-radius: 50%;
                    }
                }
            }
            .swiper
                .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal
                .swiper-pagination-bullet {
                width: 5px;
                height: 5px;
            }
        }
    }
}

@media screen and (orientation: portrait) and (min-width: 700px) {
    .reviews-container {
        .reviews {
            .review-container .text {
                &,
                * {
                    font-size: 1.2rem !important;
                }
            }
            .swiper {
                .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
                    bottom: calc(5% + 1%) !important;
                    .swiper-pagination-bullet {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(120deg) scale(0.75);
        opacity: 0;
    }
    20% {
        opacity: 0.5;
    }
    100% {
        transform: rotate(30deg) scale(1);
        opacity: 1;
    }
}
