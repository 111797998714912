.product-container {
    box-sizing: border-box;
    overflow: hidden;
    .info-strip {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-height: 60vh;
        height: fit-content;
        padding: 0 5vw;
        margin-bottom: 10vh;
        margin-top: 5vh;
        overflow: hidden;
        gap: 2vw;
        .text-container {
            display: flex;
            flex-direction: column;
            max-height: 60vh;
            .name {
                font-size: 3rem;
                font-weight: 800;
                color: #41698e;
                margin: 0;
            }
            .text {
                flex: 1;
                color: #1a3650;
                .editor-title {
                    font-size: 1.6rem;
                    font-weight: 800;
                    margin: 1vh 0;
                }
                .editor-paragraph {
                    font-size: 1.3rem;
                    line-height: 1.85rem;
                    margin: 0;
                }
            }
        }
        .img-container {
            // box-shadow: 2px 5px 15px 2px #0000002d;
            display: flex;
            justify-content: center;
            align-self: center;
            max-width: 40vw;
            min-width: 40vw;
            max-height: 30vw;
            overflow: hidden;
            position: relative;
            border-radius: 10px;
            img {
                flex: 1;
                border-radius: 10px;
                min-width: 30vw;
                height: fit-content;
                max-width: 40vw;
            }
        }
    }
}

@media screen and (orientation: portrait) {
    .product-container {
        .info-strip {
            overflow: hidden;
            margin-top: 2vh;
            margin-bottom: 5vh;
            flex-direction: column;
            gap: 3vh;
            max-height: fit-content;
            .text-container {
                max-height: 55vh;
                overflow: hidden;
                .name {
                    font-size: 3rem;
                    font-weight: 800;
                    color: #41698e;
                    margin: 0;
                    padding: 0;
                }
                .text {
                    padding: 0 1vw !important;
                    .editor-title {
                        font-size: 1.25rem;
                        font-weight: 700;
                        margin-top: 1vh;
                    }
                    .editor-paragraph {
                        margin: 0;
                        font-size: 1.25rem;
                        line-height: 1.2rem;
                    }
                }
            }
            .img-container {
                box-shadow: 2px 5px 15px 2px #0000002d;
                display: flex;
                justify-content: center;
                align-self: center;
                max-width: 100%;
                min-width: 100%;
                max-height: 50vw;
                overflow: hidden;
                position: relative;
                border-radius: 10px;
                img {
                    flex: 1;
                    border-radius: 10px;
                    min-width: 100%;
                    max-width: 100%;
                    height: fit-content;
                }
            }
        }
    }
}
