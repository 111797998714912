@import "./variables.scss";
$form-width: 18vw;

.login-container {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    // overflow: auto;
    .hilma-logo {
        position: absolute;
        top: 3.5vh;
        right: 3.5vh;
        height: 6vh;
        width: auto;
        z-index: 10;
    }
    .top-icons {
        position: absolute;
        top: 3.5vh;
        right: 8vw;
        height: 6vh;
        display: flex;
        width: 30%;
        justify-content: space-between;
        .lang {
            margin: 0 2vw;
            a {
                color: #1a3650;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: 5vh;
                text-decoration: none;
                padding: 5% 5px;
                margin: 0 2vw;
                border-radius: 3px;
                &:visited {
                    color: #1a3650;
                }
                &:hover {
                    background-color: rgba(128, 128, 128, 0.179);
                }
                * {
                    font-size: 0.77rem;
                }
                img {
                    height: 20px;
                    width: 20px;
                    padding: 0;
                }
            }
            .css-1s2u09g-control {
                border-radius: 30px;
                border: 2px solid #1a3650;
                height: 35px;
            }
            .css-319lph-ValueContainer {
                padding: 0px 8px;
                height: 100%;
            }
        }

        .home-button {
            height: 6vh;
            align-self: center;
            cursor: pointer;
            padding: 0 1vw;
            border-radius: 3px;

            &:hover {
                background-color: rgba(128, 128, 128, 0.179);
            }
        }
    }
    .bottom-blue {
        position: absolute;
        bottom: 0;
        width: 100vw;
        height: 10vh;
        clip-path: polygon(0 35%, 100% 0%, 100% 100%, 0% 100%);
        background-color: #c1dee2ad;
    }
    .right-design {
        .tall-plant {
            position: absolute;
            right: 0;
            bottom: 2vh;
            width: 13vw;
            height: auto;
            transform: translateX(40%);
        }
    }
    .side-image {
        display: flex;
        align-items: center;
        flex: 1;
        .people {
            width: 55vw;
            height: auto;
            position: absolute;
            left: 10px;
            z-index: 2;
        }
    }
    .side-text {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        animation: slide-in 0.5s ease-out;
        z-index: 3;
        box-sizing: border-box;

        .title,
        .subtitle,
        .login-btn,
        .inputs,
        .alt-container,
        .connect {
            direction: ltr;
            color: #1a3650;
            width: 25vw;
            box-sizing: border-box;
        }
        .title {
            position: relative;
            font-size: 1.6rem;
            border-bottom: 1px solid #1a3650;
            margin-bottom: 5vh;
            font-weight: 800;
            padding-bottom: 1vh;

            .code-with-back {
                position: absolute;
                top: 0;
                right: calc(-1 * (1em + 1vw));
            }
            h5,
            h6 {
                margin: 0;
                white-space: nowrap;
            }

            h6 {
                font-weight: 400;
            }
        }
        .subtitle {
            font-weight: 600;
            font-size: 1.5rem;
            margin: 1vh 0;
        }
        .inputs {
            // justify-content: center;
            // flex-direction: column;
            $height-of-error: 3vh;
            .input-container {
                align-self: center;
                margin-bottom: $height-of-error;
                .input-label {
                    font-size: 1rem;
                    font-weight: bold;
                    padding: 3px 0; //border-radius
                    padding-top: 8px;
                }
                input {
                    border-radius: 7px;
                    border: 1px solid grey;
                    padding: 0.5vh 0.75vw;
                    font-size: 1rem;
                    &.email {
                        direction: ltr;
                    }
                }
                &.true {
                    .input-label {
                        color: rgb(122, 13, 13);
                    }
                    input {
                        border: 1px solid rgb(122, 13, 13);
                    }
                }
                .error {
                    color: rgb(122, 13, 13);
                    width: 25vw;
                    padding: 0 2px;
                    font-size: $height-of-error / 2;
                    line-height: $height-of-error / 2;
                    position: absolute;
                }
            }
            & > :first-child {
                &.input-container {
                    .input-label {
                        padding-top: 0 !important;
                    }
                }
            }
        }
        .alt-container {
            position: relative;
            margin: 1vh 0;
            .or-google-label {
                background-color: white;
                width: fit-content;
                padding: 0 15px;
                font-size: 1rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .connect {
            background-color: $lightBlue;
            color: white;
            border-radius: 7px;
            text-align: center;
            border: 0;
            font-weight: bold;
            padding: 0.65vh 0; //a bit less than inputs to compensate for font size
            cursor: pointer;
            margin: 3vh 0;
            margin-bottom: 5vh;
            font-size: 1.1rem;
            transition: transform 0.15s linear;
            transform: scale(1);
            box-shadow: 2px 2px 2px #00000029;
            width: $form-width;
            align-self: center;
            &.google {
                background-color: #f4f4f4;
                font-weight: 400;
                color: #6b6b6b;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2rem;
                .google-g-logo {
                    width: 25px;
                    margin-right: 10px;
                }
            }
            &.login-btn {
                margin-bottom: 3vh;
            }
            &.false {
                background-color: #719196;
                cursor: default;
                // align-self: flex-start;
            }
            &.true {
                &:hover {
                    transform: scale(1.05);
                }
            }
        }
        .no-account-btn,
        .restore-password {
            text-align: center;
            cursor: pointer;
            background-color: transparent;
            border: 0;
            text-decoration: underline;
        }
        .bottom-buttons {
            display: flex;
            align-items: flex-start;
            justify-content: center;

            * {
                text-align: center;
            }
        }
        .restore-password {
            border-right: 1px solid black;

            &.false {
                visibility: hidden;
            }
        }
    }
    .center {
        justify-content: center;
        margin-top: 0;
        margin-bottom: 0;
        // height: 100vh;
        padding-bottom: 0;
        width: 50%;
    }
    &.rtl {
        .side-text {
            * {
                direction: rtl;
                label {
                    text-align: right;
                }
            }
        }
    }
    &.sign-up {
        // width: 30vw;
        .progress {
            width: 100%;
            height: 1px;
            background-color: #c5cfd6;
            display: flex;
            justify-content: space-between;
            margin: 6vh 0 3vh;
            .page-dot {
                width: 2.25vh;
                height: 2.25vh;
                border-radius: 50%;
                border: 1.4px solid #c5cfd6;
                background-color: #c5cfd6;
                transform: translateY(-50%);
                &.true {
                    background-color: #89c5cc;
                    border-color: #707070;
                    cursor: pointer;
                }
                &.current {
                    background-color: $lightBlue;
                }
            }
        }
        .side-text {
            display: flex;
            // align-items: flex-end;
            padding: 0 10vw;
            // * {
            //     width: 30vw;
            // }
            .title {
                font-size: 1.45rem;
                line-height: 1.2rem;
                border-bottom: 0;
            }
            .subtitle {
                font-size: 1.3rem;
                font-weight: 400;
            }
            .inputs,
            .input-container > input,
            .connect {
                width: $form-width;
            }
            .check-row {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                margin-bottom: 2vh;
                display: block;
                position: relative;
                cursor: pointer;
                label {
                    padding: 0 30px;
                    color: $menuDarkColor;
                }

                input[type="checkbox"] {
                    width: fit-content !important;
                    margin: 0 1vw;
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
                .checkmark {
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    transform: translate(-100%, -50%);
                    height: 18px;
                    width: 18px;
                    border: 1px solid #6b6b6b;
                    border-radius: 50%;
                    z-index: 1000;
                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                }
                &:hover {
                    .checkmark {
                        .dot {
                            width: 9px;
                            height: 9px;
                            background-color: rgba(204, 204, 204, 0.711);
                            opacity: 1;
                            border-radius: 50%;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            position: absolute;
                            transition: opacity 2s ease-in-out;
                        }
                    }
                }
                input[type="checkbox"]:checked {
                    & ~ .checkmark {
                        .dot {
                            width: 9px;
                            height: 9px;
                            background-color: #6b6b6b;
                            border-radius: 50%;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            position: absolute;
                        }
                        &:after {
                            display: block;
                        }
                    }
                }
                .container .checkmark:after {
                    left: 9px;
                    top: 5px;
                    width: 5px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
            hr {
                margin: 0;
                width: 100%;
            }
        }
        .bottom-buttons {
            display: flex;

            .no-account-btn {
                text-align: center;
                margin-bottom: 10vh;
            }
        }
    }
}
@media screen and (orientation: portrait) {
    .login-container {
        align-items: center;
        justify-content: center;
        overflow: auto;
        position: relative;
        .top-icons {
            display: flex;
            justify-content: space-between;
            top: 4.5vh;
            width: 80vw;

            img {
                height: 5vh;
                width: auto;
            }
        }
        .bottom-blue,
        .tall-plant {
            opacity: 0.5;
            display: none;
        }
        .side-image {
            padding: 4vh 0;
            margin-bottom: 10vh;
            .people {
                * {
                    opacity: 0.5;
                }
                opacity: 0.5;
                width: 80vw;
                height: auto;
                position: relative;
                left: initial;
            }
        }
        .side-text {
            align-items: center;
            justify-content: center;
            padding-top: 3vh;
            .title,
            .subtitle,
            .login-btn,
            .inputs,
            .alt-container,
            .connect {
                width: 80vw;
            }
            .title {
                padding: 1vh 0 !important;
            }
            .inputs {
                .input-container {
                    input {
                        padding: 1vh 5vw;
                    }
                    .error {
                        width: 80vw;
                    }
                }
            }
            .connect {
                width: 80vw;
            }
        }
        &.sign-up {
            .top-icons {
                top: 3vh;
            }
            .side-text {
                width: 80vw;
                height: 85vh;
                padding: 0;
                // * {
                //     width: 80vw;
                // }
                .inputs,
                .input-container > input,
                .connect {
                    width: 80vw;
                    &.true {
                        &:hover {
                            transform: scale(1);
                        }
                    }
                }
                .bottom-buttons {
                    display: flex;

                    .no-account-btn {
                        // margin-bottom: 5vh;
                        margin-top: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        left: -100px;
    }
    100% {
        opacity: 1;
        left: 0px;
    }
}

.MuiInput-input {
    padding: 12px 7px !important; //TODO think about more mui way to do this
}
