.bought-container {
    border-top: 13px solid #2d898b70;
    border-bottom: 30px solid #2d898b70;
    margin-top: 0 !important; //height of bullet points plus some space
    position: relative;
    height: calc(100vh - 14vh);
    box-sizing: border-box;
    .loading {
        margin: auto;
    }
    .projects-cards {
        display: flex;
        flex-direction: row;
        padding-top: 0;
        width: 90vw;
        gap: 3vw;
        margin: auto;
        & > * {
            z-index: 3;
            margin: 0 !important;
            .product-info {
                height: 30% !important;
                background-color: rgba(255, 255, 255, 0.79);
                padding: 3vh 2vw !important;
                .name {
                    padding-top: 0 !important;
                }
                .description {
                    &:after {
                        content: ' ' !important;
                    }
                }
            }
            .product-img {
                height: 70% !important;
            }
            .hilma-tag {
                min-width: 50%;
                color: #d81159;
                font-weight: 600;
                flex-direction: row-reverse;
                height: fit-content;
                .clock {
                    width: 19px !important;
                    height: auto;
                    padding: 12px;
                }
                .tag-info {
                    color: #d81159;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .info-p {
            margin: 0 2vw;
        }
    }
    .plant-container {
        .plant {
            position: absolute;
            bottom: -0px !important; //slightly less than border-bottom thickness
            right: 2vw;
            width: 90px;
            z-index: 2;
            height: auto;
        }
    }
    &.he {
        .product-info * {
            text-align: right;
        }
    }
    &.en {
        .product-info * {
            text-align: left;
        }
    }
}
